<template lang="">
    <div class="columns flight-items first-column is-vcentered">
      <div class="column flight-item-first">
        <div class="bm-input-wrap">
          <select class="input" v-model="selectedValue">
            <option value="1">Hin-und Rückflug</option>
            <option value="2">nur Hinflug</option>
          </select>
        </div>
      </div>
    </div>
    <div class="columns flight-items second-column is-vcentered">
      <div class="column c-second">
        <p class="label">Von:</p>
        <div class="bm-input-wrap">
          <input type="text" autocomplete="off"
            v-model="searchTermDeparture"
            :placeholder="selectedDepartureNameValue"
            @input="getDataDepartureFlight()">
            <ul v-if="searchFlightsDeparture?.length">
            <!-- li>
              <p>
                {{ searchFlightsDeparture?.length }}
                von {{ searchFlightsDeparture?.length }}
              </p>
            </li -->
            <li v-for="flights in searchFlightsDeparture" :key="flights.name"
              @click="selectFlightDeparture(flights.iata), selectedDepartureName(flights.location)">
              <div class="results">
                <div class="results-image-wrap">
                  <img :src="planeIcon">
                </div>
                <div class="results-content-wrap">
                  <p class="is-clickable country-row">
                    <span v-if="flights.region">
                      <b>{{ flights.region }}</b><br/>
                    </span>
                    <span v-if="flights.city">
                        {{ flights.city }} ({{ flights.location }})
                    </span>
                  </p>
                  <p class="is-clickable iata-row ">
                    {{ flights.iata }} - {{ flights.country }}
                  </p>
                </div>
              </div>
            </li>
            </ul>
        </div>
      </div>
      <div class="column flight-item-third">
        <p class="label">Nach:</p>
        <div class="bm-input-wrap">
          <input type="text" autocomplete="off"
            v-model="searchTermArrival" :placeholder="selectedArrivalNameValue"
            @input="getDataArrivalFlight()">
            <ul v-if="searchFlightsArrival?.length">
            <!-- li>
              <p>{{ searchFlightsArrival?.length }} von {{
                searchFlightsArrival?.length }}</p>
            </li -->
            <li v-for="flights in searchFlightsArrival" :key="flights.name"
              @click="selectFlightArrival(flights.iata), selectedArrivalName(flights.location)">
              <div class="results">
                <div class="results-image-wrap">
                  <img :src="planeIcon">
                </div>
                <div class="results-content-wrap">
                  <p class="is-clickable country-row ">
                    <span v-if="flights.region">
                      <b>{{ flights.region }}</b><br/>
                    </span>
                    <span v-if="flights.city">
                      {{ flights.city }} ({{ flights.location }})
                    </span>
                  </p>
                  <p class="is-clickable iata-row ">
                    {{ flights.iata }} - {{ flights.country }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="column flight-item-fourth">
        <p class="label">Hinflug:</p>
        <VueDatePicker :start-date="startDate" :min-date="minDate"
          v-model="flightDepartureDate" :enable-time-picker="false"
          placeholder="Hinflug *" auto-apply required
          @update:model-value="setDepartureDate" locale="de-DE"
          :format-locale="de" format="dd.MM.yyyy" preview-format="dd.MM.yyyy"
          model-type="yyyy-MM-dd" input-class-name="input" class="calendar">
          <template #input-icon>
            <img class="input-slot-image" alt="Datum auswählen"
              :src="calendarIcon">
          </template>
        </VueDatePicker>
      </div>
      <div class="column flight-item-fifth" v-if="selectedValue != '2'">
        <p class="label">Rückflug:</p>
        <VueDatePicker :min-date="flightDepartureDate || new Date()"
          :disabled="selectedValue === '2' || !flightDepartureDate" auto-apply
          required v-model="flightArrivalDate" :enable-time-picker="false"
          placeholder="Rückflug" @update:model-value="setDate2" locale="de-DE"
          :format-locale="de" format="dd.MM.yyyy" preview-format="dd.MM.yyyy"
          model-type="yyyy-MM-dd" input-class-name="input">
          <template #input-icon>
            <img class="input-slot-image" alt="Datum auswählen"
              :src="calendarIcon">
          </template>
        </VueDatePicker>
      </div>
      <div class="column flight-item-sixth">
        <p class="label">Personen (Erw.):</p>
        <div class="bm-input-wrap">
          <div>
            <select class="input" v-model="selectedPeople">
              <option v-for="n in 10" :key="n">{{ n }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="bm-search-button" disabled>
        <p class="label">&nbsp;</p>
        <a class="navbar-item" :href="finalUrlFlight">
          Flug suchen
        </a>
      </div>
      {{tomorrow}}
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import { de } from 'date-fns/locale'
import VueDatePicker from '@vuepic/vue-datepicker'
import axios from 'axios'

export default {
  components: { VueDatePicker },
  setup() {

    const calendarIcon = ref(require('@/assets/images/calendar-icon.svg'))
    const planeIcon = ref(require('@/assets/images/plane-icon.svg'))

    const today = ref(new Date())
    const minDate = ref(new Date(today.value.getFullYear(), today.value.getMonth(), today.value.getDate() + 3)) 

    let arrivalFlights = ref([])
    let departureFlights = ref([])
    let searchTermArrival = ref('')
    let searchTermDeparture = ref('')
    let startDate = ref('')
    let url = ref('')
    let flightDepartureDate = ref('')
    let flightArrivalDate = ref('')
    let selectedDepartureNameValue = ref('SCN - Saarbrücken')
    let selectedArrivalNameValue = ref('Nach *')
    let selectedDeparture = ref('SCN')
    let selectedArrival = ref('')
    let selectedPeople = ref('2')
    const selectedValue = ref('1')

    const handleJsonpResponse = (jsonpResponse) => {
      let jsonResponse = jsonpResponse.substring(jsonpResponse.indexOf("(") + 1, jsonpResponse.lastIndexOf(")"))
      let jsonData = JSON.parse(jsonResponse)
      return jsonData
    }

    const getDataArrivalFlight = async () => {
      try {
        const result = await axios.get(`https://api.specials.de/flight/suggest.jsonp?suggest=${searchTermArrival.value}`)
        arrivalFlights.value = handleJsonpResponse(result.data)
      } catch (error) {
        console.log(error)
      }
    }

    const getDataDepartureFlight = async () => {
      try {
        const result = await axios.get(`https://api.specials.de/flight/suggest.jsonp?suggest=${searchTermDeparture.value}`)
        departureFlights.value = handleJsonpResponse(result.data)
      } catch (error) {
        console.log(error)
      }
    }

    const selectedDepartureName = (departure) => {
      selectedDepartureNameValue.value = departure
    }

    const finalUrlFlight = computed(() => {
      const isOneWay = selectedValue.value === '2'
      console.log(isOneWay)
      if (isOneWay) {
        return `https://www.flughafen-saarbruecken.de/passagiere-besucher/fliegen/buchen/flug-buchen/?tx_yfl[…]&depdate1=${flightDepartureDate.value}&retdate1=${flightArrivalDate.value}&depapt1=${selectedDeparture.value}&dstapt1=${selectedArrival.value}&adt=${selectedPeople.value}&type=oneway&action=search`
      } else {
        return `https://www.flughafen-saarbruecken.de/passagiere-besucher/fliegen/buchen/flug-buchen/?tx_yfl[…]&depdate1=${flightDepartureDate.value}&retdate1=${flightArrivalDate.value}&depapt1=${selectedDeparture.value}&dstapt1=${selectedArrival.value}&adt=${selectedPeople.value}&action=search`
      }
    })

    const searchFlightsDeparture = computed(() => {
      if (searchTermDeparture.value === '') {
        return []
      }
      return departureFlights.value.filter(flight => flightFilter(flight, searchTermDeparture.value))
    })

    const searchFlightsArrival = computed(() => {
      if (searchTermArrival.value === '') {
        return []
      }
      return arrivalFlights.value.filter(flight => flightFilter(flight, searchTermArrival.value))
    })

    const flightFilter = (flight, input) => {
      input = input.toLowerCase()
      let subregion = flight.subregion.toLowerCase()
      let iata = flight.iata.toLowerCase()
      let location = flight.location.toLowerCase()
      let country = flight.country.toLowerCase()
      let city = flight.city.toLowerCase()

      if (iata.includes(input)
        || subregion.includes(input)
        || location.includes(input)
        || country.includes(input)
        || city.includes(input)) {
        return flight;
      }
    }

    const setDepartureDate = (value) => {
      flightDepartureDate.value = value
      flightArrivalDate.value = null
    }

    const selectedArrivalName = (arrival) => {
      selectedArrivalNameValue.value = arrival
    }

    const selectFlightDeparture = (flight) => {
      selectedDeparture.value = flight
      searchTermDeparture.value = ''
    }

    const selectFlightArrival = (flight) => {
      selectedArrival.value = flight
      searchTermArrival.value = ''
    }

    const setDate2 = (value) => {
      flightArrivalDate.value = value
    }

    return {
      de,
      calendarIcon,
      planeIcon,
      arrivalFlights,
      departureFlights,
      searchTermArrival,
      searchTermDeparture,
      startDate,
      url,
      flightDepartureDate,
      flightArrivalDate,
      getDataDepartureFlight,
      getDataArrivalFlight,
      setDepartureDate,
      selectFlightDeparture,
      selectFlightArrival,
      setDate2,
      searchFlightsDeparture,
      searchFlightsArrival,
      selectedDepartureName,
      selectedDepartureNameValue,
      selectedArrivalNameValue,
      selectedArrivalName,
      selectedDeparture,
      selectedArrival,
      selectedPeople,
      selectedValue,
      finalUrlFlight,
      today,
      minDate
    }
  }
}
</script>
<style lang="">
    
</style>