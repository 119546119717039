<template lang="">
    <div class="columns lastMinute-items">
      <div class="column lastMinute-item-first">
        <p class="label">Von:</p>
        <div class="bm-input-wrap">
          <input type="text" id="depapt1" autocomplete="off"
            v-model="searchTermDeparture"
            :placeholder="selectedDepartureNameValue"
            @input="getDataDepartureFlight()">
            <ul v-if="searchFlightsDeparture?.length">
            <!-- li>
              <p>
                {{ searchFlightsDeparture?.length }}
                von {{ searchFlightsDeparture?.length }}
              </p>
            </li -->
            <li v-for="flights in searchFlightsDeparture" :key="flights.name"
              @click="selectFlightDeparture(flights.iata), selectedDepartureName(flights.location)">
              <div class="results">
                <div class="results-image-wrap">
                  <img :src="planeIcon">
                </div>
                <div class="results-content-wrap">
                  <p class="is-clickable country-row">
                    <span v-if="flights.region">
                      <b>{{ flights.region }}</b>
                    </span>
                    <span v-if="flights.city">
                      {{ flights.city }} ({{ flights.location }})
                    </span>
                  </p>
                  <p class="is-clickable iata-row ">
                    {{ flights.iata }} - {{ flights.country }}
                  </p>
                </div>
              </div>
            </li>
            </ul>
        </div>
      </div>
      <div class="column lastMinute-item-second">
        <p class="label">Datum:</p>
        <VueDatePicker v-model="dateLastMinute" :range="true" :min-date="minDate"
          :clearable="true" locale="de-DE" :format-locale="de" format="dd.MM.yyyy"
          preview-format="dd.MM.yyyy" model-type="dd.MM.yyyy"
          input-class-name="input" placeholder="Datum auswählen" auto-apply 
          @update:model-value="setDateLastMinute" :enable-time-picker="false">
          <template #input-icon>
            <img class="input-slot-image" alt="Datum auswählen"
              :src="calendarIcon">
          </template>
        </VueDatePicker>
      </div>
      <div class="column lastMinute-item-third">
        <p class="label">Personen (Erw.):</p>
        <div class="bm-input-wrap">
          <div>
            <select class="input" v-model="selectedPeopleLastMinute">
              <option v-for="n in 10" :key="n">{{ n }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="column lastMinute-item-fourth">
        <p class="label">&nbsp;</p>
        <div class="bm-search-button" disabled>
          <a class="navbar-item" :href="finalurlLastMinute">
            Last minute suchen
          </a>
        </div>
      </div>
    </div>
</template>
<script>
import { ref, computed } from 'vue'
import { de } from 'date-fns/locale'
import VueDatePicker from '@vuepic/vue-datepicker'
import axios from 'axios'

export default {
  components: { VueDatePicker },
  setup() {
    const calendarIcon = ref(require('@/assets/images/calendar-icon.svg'))
    const planeIcon = ref(require('@/assets/images/plane-icon.svg'))

    const today = ref(new Date())
    const minDate = ref(new Date(today.value.getFullYear(), today.value.getMonth(), today.value.getDate() + 4)) 

    let departureFlights = ref([])
    let selectedDeparture = ref('SCN')
    let selectedDepartureNameValue = ref('SCN - Saarbrücken')
    let searchTermDeparture = ref('')
    let datePackage = ref('')
    let dateLastMinute = ref([])
    let selectedPeopleLastMinute = ref('2')

    const handleJsonpResponse = (jsonpResponse) => {
      let jsonResponse = jsonpResponse.substring(jsonpResponse.indexOf("(") + 1, jsonpResponse.lastIndexOf(")"))
      let jsonData = JSON.parse(jsonResponse)
      return jsonData
    }

    const selectFlightDeparture = (flight) => {
      selectedDeparture.value = flight
      searchTermDeparture.value = ''
    }

    const setDateLastMinute = (value) => {
      datePackage.value = value
    }

    const selectedDepartureName = (departure) => {
      selectedDepartureNameValue.value = departure
    }

    const setDatePackage = (value) => {
      datePackage.value = value
    }

    const finalurlLastMinute = computed(() => {
      return `https://www.flughafen-saarbruecken.de/passagiere-besucher/fliegen/buchen/last-minute-buchen/?action=destination&agent=991784&product=package&language=de&startDate=${(dateLastMinute && dateLastMinute.value.length > 0 ? dateLastMinute.value[0] : '')}&endDate=${(dateLastMinute && dateLastMinute.value.length > 1 ? dateLastMinute.value[1] : '')}&departures=${selectedDeparture.value}&adults=${selectedPeopleLastMinute.value}&minRecommrate=0&minPrice=0&rSort=quality&sortType=up&iframe=1&action=destination&offset=0`
    })

    const getDataDepartureFlight = async () => {
      try {
        const result = await axios.get(`https://api.specials.de/flight/suggest.jsonp?suggest=${searchTermDeparture.value}`)
        departureFlights.value = handleJsonpResponse(result.data)
      } catch (error) {
        console.log(error)
      }
    }

    const searchFlightsDeparture = computed(() => {
      if (searchTermDeparture.value === '') {
        return []
      }
      return departureFlights.value.filter(flight => flightFilter(flight, searchTermDeparture.value))
    })

    const flightFilter = (flight, input) => {
      input = input.toLowerCase()
      let subregion = flight.subregion.toLowerCase()
      let iata = flight.iata.toLowerCase()
      let location = flight.location.toLowerCase()
      let country = flight.country.toLowerCase()
      let city = flight.city.toLowerCase()

      if (iata.includes(input)
        || subregion.includes(input)
        || location.includes(input)
        || country.includes(input)
        || city.includes(input)) {
        return flight;
      }
    }

    return {
      de,
      calendarIcon,
      planeIcon,
      finalurlLastMinute,
      dateLastMinute,
      datePackage,
      selectedPeopleLastMinute,
      setDateLastMinute,
      setDatePackage,
      getDataDepartureFlight,
      searchTermDeparture,
      selectFlightDeparture,
      selectedDepartureNameValue,
      selectedDepartureName,
      searchFlightsDeparture,
      today,
      minDate
    }
  }
}
</script>
<style></style>